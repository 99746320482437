import * as Types from './types.js'
import {
  contractAddresses,
  supportedPools,
  supportedInvestmentPools,
} from './constants.js'

import UNIV2PairAbi from './abi/uni_v2_lp.json'

import SushiAbi from './abi/sushi.json'
import MasterChefAbi from './abi/masterchef.json'
import ERC20Abi from './abi/erc20.json'
import WETHAbi from './abi/weth.json'
import SashimiBarAbi from './abi/sashimiBar.json'
import InvestmentAbi from './abi/investment.json'
import LPBarAbi from './abi/LPBar';
import vaultABI from '../../constants/abi/vault/vaultABI';
import SashimiRouterAbi from './abi/sashimiRouter';
import BusdStakingAbi from './abi/busdStaking'
import AirdropAbi from './abi/airdrop.json'

export class Contracts {
  constructor(provider, networkId, web3, options) {
    this.web3 = web3
    this.defaultConfirmations = options.defaultConfirmations
    this.autoGasMultiplier = options.autoGasMultiplier || 1.5
    this.confirmationType =
      options.confirmationType || Types.ConfirmationType.Confirmed
    this.defaultGas = options.defaultGas
    this.defaultGasPrice = options.defaultGasPrice

    this.sushi = new this.web3.eth.Contract(SushiAbi)
    this.masterChef = new this.web3.eth.Contract(MasterChefAbi)
    this.weth = new this.web3.eth.Contract(WETHAbi)
    this.sashimiBar = new this.web3.eth.Contract(SashimiBarAbi)
    this.investment = new this.web3.eth.Contract(InvestmentAbi)
    this.sashimiRouter = new this.web3.eth.Contract(SashimiRouterAbi)
    this.busdStaking = new this.web3.eth.Contract(BusdStakingAbi)
    this.bnbStaking = new this.web3.eth.Contract(BusdStakingAbi)
    this.bnbAirdrop = new this.web3.eth.Contract(AirdropAbi)
    this.busdAirdrop = new this.web3.eth.Contract(AirdropAbi)

    this.pools = supportedPools.map((pool) =>
      Object.assign(pool, {
        lpAddress: pool.lpAddresses[networkId],
        tokenAddress: pool.tokenAddresses[networkId],
        lpContract: new this.web3.eth.Contract(UNIV2PairAbi),
        tokenContract: new this.web3.eth.Contract(ERC20Abi),
        // In TS, use ?: Contract
        lpBarAddress: pool.lpBarAddresses ? pool.lpBarAddresses[networkId] : '',
        lpBarContract: pool.lpBarAddresses ? new this.web3.eth.Contract(LPBarAbi) : null,
        sashimiPlateContract: pool.isSashimiPlate ? new this.web3.eth.Contract(vaultABI) : null,
        uniV2LPContract: pool.isSashimiPlate || pool.uniV2Pivot ? new this.web3.eth.Contract(UNIV2PairAbi) : null,
      }),
    );

    this.investmentPools = supportedInvestmentPools.map((pool) =>
      Object.assign(pool, {
        lpAddress: pool.lpAddresses[networkId],
        depositAddress: pool.depositAddresses[networkId],
        providerAddress: pool.providerAddresses[networkId],
        lpContract: new this.web3.eth.Contract(UNIV2PairAbi),
        pivotLpAddress: pool.pivotLpAddresses[networkId],
        pivotLpContract: new this.web3.eth.Contract(UNIV2PairAbi),
      }),
    )

    this.setProvider(provider, networkId)
    this.setDefaultAccount(this.web3.eth.defaultAccount)
  }

  setProvider(provider, networkId) {
    const setProvider = (contract, address) => {
      contract.setProvider(provider)
      if (address) contract.options.address = address
      else console.error('Contract address not found in network', networkId)
    }

    setProvider(this.sushi, contractAddresses.sushi[networkId])
    setProvider(this.masterChef, contractAddresses.masterChef[networkId])
    setProvider(this.weth, contractAddresses.weth[networkId])
    setProvider(this.sashimiBar, contractAddresses.sashimiBar[networkId])
    setProvider(this.investment, contractAddresses.investment[networkId])
    setProvider(this.sashimiRouter, contractAddresses.sashimiRouter[networkId])
    setProvider(this.busdStaking, contractAddresses.busdStaking[networkId])
    setProvider(this.bnbStaking, contractAddresses.bnbStaking[networkId])
    setProvider(this.bnbAirdrop, contractAddresses.bnbAirdrop[networkId])
    setProvider(this.busdAirdrop, contractAddresses.busdAirdrop[networkId])

    this.pools.forEach(
      ({ lpContract, lpAddress, tokenContract, tokenAddress, lpBarAddress, lpBarContract,
         sashimiPlateContract, uniV2LPAddress, uniV2LPContract }) => {
        setProvider(lpContract, lpAddress)
        setProvider(tokenContract, tokenAddress)
        if (lpBarAddress && lpBarContract) {
          setProvider(lpBarContract, lpBarAddress)
        }
        // if (sashimiPlateContract && uniV2LPContract) {
        if (sashimiPlateContract) {
          setProvider(sashimiPlateContract, lpAddress)
        }
        if (uniV2LPContract) {
          setProvider(uniV2LPContract, uniV2LPAddress)
        }
      },
    )

    this.investmentPools.forEach(
      ({ lpContract, lpAddress, pivotLpContract, pivotLpAddress}) => {
        setProvider(lpContract, lpAddress);
        setProvider(pivotLpContract, pivotLpAddress);
      },
    )
  }

  setDefaultAccount(account) {
    this.sushi.options.from = account
    this.masterChef.options.from = account
    this.sashimiBar.options.from = account
    this.investment.options.from = account
    this.sashimiRouter.options.from = account
    this.busdStaking.options.from = account
    this.bnbStaking.options.from = account
    this.bnbAirdrop.options.from = account
    this.busdAirdrop.options.from = account
  }

  // async callContractFunction(method, options) {
  //   const {
  //     confirmations,
  //     confirmationType,
  //     autoGasMultiplier,
  //     ...txOptions
  //   } = options
  //
  //   if (!this.blockGasLimit) {
  //     await this.setGasLimit()
  //   }
  //
  //   if (!txOptions.gasPrice && this.defaultGasPrice) {
  //     txOptions.gasPrice = this.defaultGasPrice
  //   }
  //
  //   if (confirmationType === Types.ConfirmationType.Simulate || !options.gas) {
  //     let gasEstimate
  //     if (
  //       this.defaultGas &&
  //       confirmationType !== Types.ConfirmationType.Simulate
  //     ) {
  //       txOptions.gas = this.defaultGas
  //     } else {
  //       try {
  //         console.log('estimating gas')
  //         gasEstimate = await method.estimateGas(txOptions)
  //       } catch (error) {
  //         const data = method.encodeABI()
  //         const { from, value } = options
  //         const to = method._parent._address
  //         error.transactionData = { from, value, data, to }
  //         throw error
  //       }
  //
  //       const multiplier = autoGasMultiplier || this.autoGasMultiplier
  //       const totalGas = Math.floor(gasEstimate * multiplier)
  //       txOptions.gas =
  //         totalGas < this.blockGasLimit ? totalGas : this.blockGasLimit
  //     }
  //
  //     if (confirmationType === Types.ConfirmationType.Simulate) {
  //       let g = txOptions.gas
  //       return { gasEstimate, g }
  //     }
  //   }
  //
  //   if (txOptions.value) {
  //     txOptions.value = new BigNumber(txOptions.value).toFixed(0)
  //   } else {
  //     txOptions.value = '0'
  //   }
  //
  //   const promi = method.send(txOptions)
  //
  //   const OUTCOMES = {
  //     INITIAL: 0,
  //     RESOLVED: 1,
  //     REJECTED: 2,
  //   }
  //
  //   let hashOutcome = OUTCOMES.INITIAL
  //   let confirmationOutcome = OUTCOMES.INITIAL
  //
  //   const t =
  //     confirmationType !== undefined ? confirmationType : this.confirmationType
  //
  //   if (!Object.values(Types.ConfirmationType).includes(t)) {
  //     throw new Error(`Invalid confirmation type: ${t}`)
  //   }
  //
  //   let hashPromise
  //   let confirmationPromise
  //
  //   if (
  //     t === Types.ConfirmationType.Hash ||
  //     t === Types.ConfirmationType.Both
  //   ) {
  //     hashPromise = new Promise((resolve, reject) => {
  //       promi.on('error', (error) => {
  //         if (hashOutcome === OUTCOMES.INITIAL) {
  //           hashOutcome = OUTCOMES.REJECTED
  //           reject(error)
  //           const anyPromi = promi
  //           anyPromi.off()
  //         }
  //       })
  //
  //       promi.on('transactionHash', (txHash) => {
  //         if (hashOutcome === OUTCOMES.INITIAL) {
  //           hashOutcome = OUTCOMES.RESOLVED
  //           resolve(txHash)
  //           if (t !== Types.ConfirmationType.Both) {
  //             const anyPromi = promi
  //             anyPromi.off()
  //           }
  //         }
  //       })
  //     })
  //   }
  //
  //   if (
  //     t === Types.ConfirmationType.Confirmed ||
  //     t === Types.ConfirmationType.Both
  //   ) {
  //     confirmationPromise = new Promise((resolve, reject) => {
  //       promi.on('error', (error) => {
  //         if (
  //           (t === Types.ConfirmationType.Confirmed ||
  //             hashOutcome === OUTCOMES.RESOLVED) &&
  //           confirmationOutcome === OUTCOMES.INITIAL
  //         ) {
  //           confirmationOutcome = OUTCOMES.REJECTED
  //           reject(error)
  //           const anyPromi = promi
  //           anyPromi.off()
  //         }
  //       })
  //
  //       const desiredConf = confirmations || this.defaultConfirmations
  //       if (desiredConf) {
  //         promi.on('confirmation', (confNumber, receipt) => {
  //           if (confNumber >= desiredConf) {
  //             if (confirmationOutcome === OUTCOMES.INITIAL) {
  //               confirmationOutcome = OUTCOMES.RESOLVED
  //               resolve(receipt)
  //               const anyPromi = promi
  //               anyPromi.off()
  //             }
  //           }
  //         })
  //       } else {
  //         promi.on('receipt', (receipt) => {
  //           confirmationOutcome = OUTCOMES.RESOLVED
  //           resolve(receipt)
  //           const anyPromi = promi
  //           anyPromi.off()
  //         })
  //       }
  //     })
  //   }
  //
  //   if (t === Types.ConfirmationType.Hash) {
  //     const transactionHash = await hashPromise
  //     if (this.notifier) {
  //       this.notifier.hash(transactionHash)
  //     }
  //     return { transactionHash }
  //   }
  //
  //   if (t === Types.ConfirmationType.Confirmed) {
  //     return confirmationPromise
  //   }
  //
  //   const transactionHash = await hashPromise
  //   if (this.notifier) {
  //     this.notifier.hash(transactionHash)
  //   }
  //   return {
  //     transactionHash,
  //     confirmation: confirmationPromise,
  //   }
  // }

  // async callConstantContractFunction(method, options) {
  //   const m2 = method
  //   const { blockNumber, ...txOptions } = options
  //   return m2.call(txOptions, blockNumber)
  // }

  // async setGasLimit() {
  //   const block = await this.web3.eth.getBlock('latest')
  //   this.blockGasLimit = block.gasLimit - SUBTRACT_GAS_LIMIT
  // }
}
